<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import axios from "axios";
import mobileView from "../../../components/mobile-view.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Pengguna",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pengguna",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      dataKey: "user",
      screenWidth: 0,
      role: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "users",
      columns: [
        {
          prop: "role",
          label: "Role",
          width: "150",
          badge: true,
          center: true,
        },
        {
          prop: "name",
          label: "Nama",
          width: "250",
        },
        {
          prop: "phone_number",
          label: "Nomor Hp",
          width: "250",
        },
        {
          prop: "email",
          label: "Email",
          width: "250",
          textdefault: true,
        },
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          width: "250",
          date: true,
        },
        {
          prop: "updated_at",
          label: "Tgl Diperbarui",
          width: "250",
          date: true,
        },
      ],
    };
  },
  methods: {
    getDataRole() {
      axios
        .get(this.apiUrl)
        .then((response) => response.data)
        .then((res) => {
          const uniqueRoles = [...new Set(res.data.map((d) => d.role))];

          this.role = uniqueRoles.map((role) => ({
            name: role,
            value: role,
            label: role,
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.getDataRole();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-form-add="true"
          :form-url="'/pengguna/tambah'"
          :with-filter-name="true"
          :with-delete="true"
          :with-print="false"
          :with-edit="true"
          :with-show="false"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :with-fixed="true"
          :with-filter-role="true"
          :select-options="role"
          title-filter="Filter Berdasarkan Nama, Email, & No Handphone"
          filter-with="name"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    :with-filter-text="true"
    :with-filter-role="true"
    :select-options="role"
    title-filter="Filter Berdasarkan Nama, Email, & No HP"
    :form-url="'/pengguna/tambah'"
    :data-url="apiUrl"
    :card-default="false"
    :card-user="true"
    skeleton-radius="5px"
    skeleton-height="150px"
  ></mobileView>
</template>
